import * as React from "react"
import { useState, useEffect } from "react"
import { Helmet } from "react-helmet"

import HeroStart from "../components/hero-start"
import PageHeader from "../components/page-header"
import Footer from "../components/footer"
import CardSelect from "../components/card-select"
import VideoOverlay from "../components/video-overlay"

import "../scss/site.scss"
//import StickyCta from "../components/sticky-cta"

const IndexPage = () => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [caption, setCaption] = useState(null)
  const [url, setUrl] = useState(null)
  const [signUrl, setSignUrl] = useState(null)

  useEffect(() => {
    if (showOverlay) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = ""
    }
    return () => {
      document.body.style.overflowY = ""
    }
  }, [showOverlay])

  const handleShowOverlay = (caption, url, signUrl) => {
    if (caption) setCaption(caption)
    if (url) setUrl(url)
    if (signUrl) setSignUrl(signUrl)
    setShowOverlay(!showOverlay)
  }

  const videoOverlayId = "start-video-overlay"

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Demokrati Pågår | </title>
        <link rel="canonical" href="https://www.demokratipagar.se/"></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:url" content="https://www.demokratipagar.se/"></meta>
        <meta property="og:title" content="Demokrati Pågår"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-body">
        <PageHeader></PageHeader>
        <div
          role="main"
          id="main"
          className="o-main c-main-background c-main-background--start"
        >
          <HeroStart
            title="Demokrati pågår"
            text="Demokrati pågår är ett digitalt klassrumsmaterial för åk 4-6. Med hjälp av materialet kan du jobba med att stärka förståelsen för demokratins värden och förutsättningar samt förmågan till att bidra i ett demokratiskt samtal hos dina elever."
            video="yes"
            buttonText="Se filmen om demokrati"
            onClick={() =>
              handleShowOverlay(
                "Vad är demokrati?",
                "https://player.vimeo.com/video/527256292?api=1&player_id=vimeoplayer&title=0&byline=0&portrait=0&color=0BA126",
                "https://vimeo.com/531208107"
              )
            }
            ariaControls={videoOverlayId}
          />
          <section className="c-card-grid">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-lg-4">
                  <CardSelect
                    modifier="white"
                    to="/om-demokrati"
                    title="Om demokrati"
                    text="Fördjupa elevernas förståelse för demokratins värden och principer. Korta intervjuklipp och bildspel om demokratins byggstenar."
                    listheading="Direkt till övningarna"
                    links={[
                      {
                        text: "Demokratiska principer",
                        to: "/om-demokrati/demokratiska-principer",
                      },
                      {
                        text: "Röster om demokrati",
                        to: "/om-demokrati/roster-om-demokrati",
                      },
                    ]}
                  />
                </div>
                <div className="col-xs-12 col-lg-4">
                  <CardSelect
                    modifier="white"
                    to="historiska-perspektiv"
                    title="Historiska perspektiv"
                    text="Skapa överblick och ge eleverna sammanhang genom ett historiskt perspektiv på svensk demokrati. Tidslinje med frågor, personberättelser och historiska källor."
                    listheading="Direkt till övningarna"
                    links={[
                      {
                        text: "Tidslinjen",
                        to: "/historiska-perspektiv/tidslinjen-oversikt",
                      },
                      {
                        text: "Utan demokratiska rättigheter",
                        to:
                          "historiska-perspektiv/utan-demokratiska-rattigheter",
                      },
                    ]}
                  />
                </div>
                <div className="col-xs-12 col-lg-4">
                  <CardSelect
                    modifier="white"
                    to="demokratiska-samtal"
                    title="Demokratiska samtal"
                    text="Träna elevernas demokratiska förmågor. Rättvisedilemma och kreativa övningar."
                    listheading="Direkt till övningarna"
                    links={[
                      {
                        text: "Vad är rättvisa?",
                        to: "/demokratiska-samtal/vad-ar-rattvisa",
                      },
                      {
                        text: "Skapa demokratiska samtal",
                        to: "/demokratiska-samtal/skapa-demokratiska-samtal",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
        <VideoOverlay
          elementId={videoOverlayId}
          show={showOverlay}
          caption={caption}
          url={url}
          signUrl={signUrl}
          toggleShow={() => setShowOverlay(!showOverlay)}
        />
        {/*<StickyCta />*/}
      </div>
    </>
  )
}

export default IndexPage
