import * as React from "react"
import ButtonPlay from "../components/button-play"

const HeroStart = props => {
  return (
    <header className="c-hero c-hero--start">
      <div className="container">
        <div className="c-hero__content">
          <h1 className="c-hero__title">{props.title}</h1>
          <p className="c-hero__preamble">{props.text}</p>

          {props.video && props.onClick && props.buttonText && (
            <ButtonPlay
              text={props.buttonText}
              onClick={props.onClick}
              ariaControls={props.ariaControls}
            />
          )}
        </div>
      </div>
    </header>
  )
}

export default HeroStart
